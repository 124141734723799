import { Flex, HStack, StackSeparator, Table, Text, VStack } from '@chakra-ui/react';
import { usePaywall } from 'app/acl/paywall';
import { Button } from 'components/ui/button';
import { Tooltip } from 'components/ui/tooltip';
import { useOrg } from 'hooks/useOrg';
import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { FilingInstance, FilingStatus } from 'types/shared-types';
import { getCountryDisplay } from 'utils';
import { toDateShort } from 'utils/dates';
import { formatCurrency } from 'utils/utils';

import FilingStateBadge from '../filing-state-badge';
import { FilingTableActions } from './filling-table-actions';

type FilingTableRowProps = {
  rowData: FilingInstance;
  isApproveButtonLoading: boolean;
  approvalStatus?: { invalid_products_count: number; invalid_addresses_count: number };
  visibleColumns: string[];
  onOpenFilingDetailsDrawer: () => void;
  setSelectedRowData: (data: FilingInstance) => void;
};

const FilingTableRow = ({
  rowData,
  isApproveButtonLoading,
  approvalStatus,
  visibleColumns,
  onOpenFilingDetailsDrawer,
  setSelectedRowData,
}: FilingTableRowProps) => {
  let hasSuccessfullyCheckedOut = false;
  const [, setSearchParams] = useSearchParams();
  const [menuVisibility, setMenuVisibility] = useState<'visible' | 'hidden'>('hidden');
  const { isPaidUser, openPaymentCheckout } = usePaywall();
  const { isTest } = useOrg();
  const {
    id,
    status,
    start_date,
    end_date,
    date_filed,
    due_date,
    amount,
    amount_calculated,
    amount_tax_collected,
    country_code,
    amount_adjusted,
    amount_penalties,
    amount_discounts,
    amount_fees,
    state_code,
    total_tax_liability,
  } = rowData;

  const blockFiling = approvalStatus
    ? approvalStatus.invalid_products_count > 0 || approvalStatus.invalid_addresses_count > 49
    : false;

  const handleEnter = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'Enter') {
      // e.prevenTable.Cellefault();
      handleRowClick();
    }
  };

  const handleRowClick = () => {
    onOpenFilingDetailsDrawer();
    setSelectedRowData(rowData);
  };

  const openFilingModal = async () => {
    if (isPaidUser || isTest) {
      setSearchParams({ editFiling: 'true', filingId: id });
      return;
    }
    openPaymentCheckout({
      onSuccess: () => {
        if (isPaidUser) {
          setSearchParams({ editFiling: 'true', filingId: id });
        } else {
          hasSuccessfullyCheckedOut = true;
        }
      },
      onClose: () => {
        if (hasSuccessfullyCheckedOut) {
          setSearchParams({ editFiling: 'true', filingId: id });
        }
      },
    });
  };

  const isCollectedTaxHigher = Number(amount_tax_collected || 0) > Number(amount_calculated || 0);

  return (
    <Table.Row
      cursor={'pointer'}
      onClick={handleRowClick}
      _hover={{
        bg: '#EFEFF3',
      }}
      onMouseEnter={() => setMenuVisibility('visible')}
      onMouseLeave={() => setMenuVisibility('hidden')}
      tabIndex={0}
      onKeyDown={handleEnter}
    >
      {visibleColumns.includes('Country') && (
        <Table.Cell>
          <Text fontWeight={400}>{getCountryDisplay(country_code)}</Text>
        </Table.Cell>
      )}
      {visibleColumns.includes('Jurisdiction') && (
        <Table.Cell>
          <Text fontWeight={400}>{state_code}</Text>
        </Table.Cell>
      )}
      {visibleColumns.includes('start date') && (
        <Table.Cell>
          <Flex alignItems="center" minWidth="100%" flexWrap="nowrap">
            <Text>{toDateShort(start_date)}</Text>
          </Flex>
        </Table.Cell>
      )}
      {visibleColumns.includes('end date') && (
        <Table.Cell>
          <Flex alignItems="center" minWidth="100%" flexWrap="nowrap">
            <Text>{toDateShort(end_date)}</Text>
          </Flex>
        </Table.Cell>
      )}
      {visibleColumns.includes('Filed Date') && (
        <Table.Cell>
          <Flex alignItems="center" minWidth="100%" flexWrap="nowrap">
            <Text>{toDateShort(date_filed)}</Text>
          </Flex>
        </Table.Cell>
      )}
      {visibleColumns.includes('Due Date') && (
        <Table.Cell>
          <Text>{toDateShort(due_date)}</Text>
        </Table.Cell>
      )}
      {visibleColumns.includes('total liability') && (
        <Table.Cell textAlign="end" height={'40px'}>
          {Number(total_tax_liability) >= 1 ? (
            <Tooltip
              content={
                <VStack separator={<StackSeparator borderColor="#383D58" />} gap={2} align="stretch">
                  <Flex justifyContent={'space-between'}>
                    <Text color={'white'} fontWeight={500}>
                      {isCollectedTaxHigher ? 'Collected Tax' : 'Calculated Tax'}
                    </Text>
                    <Text color={'white'}> {formatCurrency(amount)}</Text>
                  </Flex>
                  <Flex justifyContent={'space-between'}>
                    <Text color={'white'} fontWeight={500}>
                      Adjustment
                    </Text>
                    <Text color={'white'}> {formatCurrency(amount_adjusted)}</Text>
                  </Flex>
                  <Flex justifyContent={'space-between'}>
                    <Text color={'white'} fontWeight={500}>
                      Fees
                    </Text>
                    <Text color={'white'}> {formatCurrency(amount_fees)}</Text>
                  </Flex>
                  <Flex justifyContent={'space-between'}>
                    <Text color={'white'} fontWeight={500}>
                      Penalties
                    </Text>
                    <Text color={'white'}> {formatCurrency(amount_penalties)}</Text>
                  </Flex>
                  <Flex justifyContent={'space-between'}>
                    <Text color={'white'} fontWeight={500}>
                      Discount
                    </Text>
                    <Text color={'white'}> {formatCurrency(amount_discounts)}</Text>
                  </Flex>
                </VStack>
              }
            >
              <Text>{formatCurrency(total_tax_liability)}</Text>
            </Tooltip>
          ) : (
            <Text>{formatCurrency(total_tax_liability)}</Text>
          )}
        </Table.Cell>
      )}
      {visibleColumns.includes('tax collected') && (
        <Table.Cell width={'150px'} textAlign="end">
          <Text>{formatCurrency(amount_tax_collected)}</Text>
        </Table.Cell>
      )}
      {visibleColumns.includes('filing status') && (
        <Table.Cell>
          <FilingStateBadge status={status} />
        </Table.Cell>
      )}
      <Table.Cell>
        <HStack justifyContent={'flex-end'} height={'32px'}>
          {status === FilingStatus.UNFILED && (
            <Tooltip
              content={
                <Text whiteSpace="pre-wrap">
                  {blockFiling
                    ? `We&apos;re unable to calculate liabilities because there are too many unapproved products or invalid
                addresses.`
                    : `The Total Liability was updated. Please reapprove this filing.`}
                </Text>
              }
            >
              <Button
                onClick={e => {
                  e.stopPropagation();
                  openFilingModal();
                }}
                colorPalette="blue"
                variant="outline"
                minWidth="80px"
                disabled={blockFiling}
                loading={isApproveButtonLoading}
              >
                Approve
              </Button>
            </Tooltip>
          )}
          <FilingTableActions
            visibility={menuVisibility}
            filingId={id}
            stateCode={state_code}
            countryCode={country_code}
          />
        </HStack>
      </Table.Cell>
    </Table.Row>
  );
};

export default FilingTableRow;
