import { Button } from 'components/ui/button';
import { Tooltip } from 'components/ui/tooltip';
import { useHotkeys } from 'react-hotkeys-hook';
import { useSearchParams } from 'react-router-dom';

const FilingHistoryButton = () => {
  const [, setSearchParams] = useSearchParams();
  useHotkeys('ctrl+h', () => setSearchParams({ showFilingHistory: 'true' }), []);

  return (
    <Tooltip content={'View Filing History'}>
      <Button
        width={'100px'}
        aria-label={'Filings history'}
        variant={'solid'}
        colorPalette="lightGray"
        onClick={() => {
          setSearchParams({ showFilingHistory: 'true' });
        }}
      >
        History
      </Button>
    </Tooltip>
  );
};

export default FilingHistoryButton;
