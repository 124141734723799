import { HStack, Stack } from '@chakra-ui/react';
import { useQuery } from '@tanstack/react-query';
import { FILINGS_STATE_KEY, getFilingApprovalStatuses, getFilings } from 'apis/filing-apis';
import { getRegistrations, REGISTRATION_STATE_KEY } from 'apis/registration-apis';
import { usePaywall } from 'app/acl/paywall';
import AppHeader from 'app/app-header';
import { FilingTrackingToolbar } from 'app-header-toolbar/filing-tracking-toolbar';
import AlertBanner from 'components/alert/alert';
import { PaginationButtons } from 'components/pagination/pagination-buttons';
import { endOfMonth } from 'date-fns/endOfMonth';
import { startOfMonth } from 'date-fns/startOfMonth';
import { useOrg } from 'hooks/useOrg';
import { DEFAULT_PAGE_SIZE, useTableFilters } from 'hooks/useTableFilters';
import { FilingStatus, UnapprovedFilingMetaData } from 'types/shared-types';
import { getFormattedDate } from 'utils/dates';

import { FilingBulkApprove } from './components/filing-bulk-approve';
import { FilingTable } from './components/filing-table/table';

const Filings = () => {
  const { orgId, isTest } = useOrg();
  const { isPaidUser } = usePaywall();

  const currentMonthStart = getFormattedDate(startOfMonth(new Date()), 'yyyy-MM-dd');
  const currentMonthEnd = getFormattedDate(endOfMonth(new Date()), 'yyyy-MM-dd');
  const tableFilters = useTableFilters({
    status__in: [FilingStatus.FILING, FilingStatus.UNFILED, FilingStatus.FILED],
    date_filed__gte: currentMonthStart,
    date_filed__lte: currentMonthEnd,
  });

  const {
    page,
    size,
    status__in,
    date_filed__gte,
    date_filed__lte,
    state_code,
    country_code,
    order_by,
    start_date,
    end_date,
    setFilters,
  } = tableFilters;

  const { isPending: isFilingPending, data } = useQuery({
    queryKey: [
      FILINGS_STATE_KEY,
      orgId,
      page,
      size,
      status__in,
      date_filed__gte,
      date_filed__lte,
      state_code,
      country_code,
      order_by,
      start_date,
      end_date,
    ],
    queryFn: async () => {
      const filingsResponse = await getFilings({
        orgId,
        params: {
          page,
          size,
          status__in,
          date_filed__gte,
          date_filed__lte,
          state_code,
          country_code,
          order_by,
          start_date,
          end_date,
        },
      });
      const approvalStatsResponse = await getFilingApprovalStatuses(
        orgId,
        filingsResponse.data.items.map(({ id }) => id)
      );

      return {
        filings: filingsResponse.data,
        approvalStatsByIds: approvalStatsResponse.data,
      };
    },
    select: ({ filings: { items, ...rest }, approvalStatsByIds }) => {
      const unApprovedFilings = items.filter(({ status, id }) => {
        let blockFiling = false;
        if (approvalStatsByIds?.[id]) {
          const { invalid_addresses_count, invalid_products_count } = approvalStatsByIds[id];
          blockFiling = invalid_products_count > 0 || invalid_addresses_count > 49;
        }
        return status === FilingStatus.UNFILED && !blockFiling;
      });

      const unapprovedFilingsMetaData = unApprovedFilings.reduce(
        (
          acc,
          {
            id,
            total_tax_liability,
            amount,
            amount_sales,
            amount_tax_collected,
            amount_calculated,
            amount_adjusted,
            amount_fees,
            amount_penalties,
            amount_discounts,
          }
        ) => {
          acc.ids.push(id);
          acc.count += 1;
          acc.accumulated_amount += Number(amount);
          acc.accumulated_total_tax_liability += Number(total_tax_liability);
          acc.accumulated_amount_sales += Number(amount_sales);
          acc.accumulated_amount_tax_collected += Number(amount_tax_collected);
          acc.accumulated_amount_calculated += Number(amount_calculated);
          acc.accumulated_amount_adjusted += Number(amount_adjusted);
          acc.accumulated_amount_fees += Number(amount_fees);
          acc.accumulated_amount_penalties += Number(amount_penalties);
          acc.accumulated_amount_discounts += Number(amount_discounts);

          return acc;
        },
        {
          count: 0,
          ids: [],
          accumulated_amount: 0,
          accumulated_total_tax_liability: 0,
          accumulated_amount_sales: 0,
          accumulated_amount_tax_collected: 0,
          accumulated_amount_calculated: 0,
          accumulated_amount_adjusted: 0,
          accumulated_amount_fees: 0,
          accumulated_amount_penalties: 0,
          accumulated_amount_discounts: 0,
        } as UnapprovedFilingMetaData
      );

      return {
        ...rest,
        items,
        filingIds: items.map(({ id }) => id),
        unapprovedFilingsMetaData,
        approvalStatsByIds,
      };
    },
  });

  const filingData = data?.items || [];
  const totalPages = data?.pages ?? 0;
  const isPaginationEnable = !!(filingData.length > 0 && (data?.total ?? 0) > DEFAULT_PAGE_SIZE && isPaidUser);
  const approvalStatsByIds = data?.approvalStatsByIds ?? {};

  const { isPending: isRegistrationLoading, data: registrations } = useQuery({
    queryKey: [REGISTRATION_STATE_KEY, orgId, page, size],
    queryFn: async () => {
      const { data } = await getRegistrations(orgId, { page, size });
      return data;
    },
    enabled: !!orgId,
  });

  const allPending = isFilingPending || isRegistrationLoading;
  const haveRegistrations = !!registrations?.items?.length;

  return (
    <>
      <HStack gap={1} justifyContent={'space-between'}>
        <AppHeader />
        <FilingTrackingToolbar tableFilters={tableFilters} />
      </HStack>
      <Stack>
        {filingData?.length > 0 && isPaidUser && (
          <AlertBanner
            message="Completed filings remain on the filings page until the end of the filing month, then shift to Filings History."
            width={{ base: '100%', md: '75%', sm: '100%' }}
          />
        )}
        {data?.unapprovedFilingsMetaData && isPaidUser && !isFilingPending && (
          <FilingBulkApprove
            unapprovedFilingsMetaData={data.unapprovedFilingsMetaData}
            approvalStats={approvalStatsByIds}
          />
        )}
        <FilingTable
          data={filingData}
          approvalStatsByIds={approvalStatsByIds}
          haveFillingPageAccess={isPaidUser || isTest}
          haveRegistrations={haveRegistrations}
          isPending={allPending}
          isFilterApplied={!!(country_code || state_code || start_date || end_date)}
        />

        {isPaginationEnable && (
          <PaginationButtons size={size} currentPage={page} totalPages={totalPages} setFilters={setFilters} />
        )}
      </Stack>
    </>
  );
};

export default Filings;
