import { FilingsService } from '_api-client';
import {
  ApiResponse,
  FilingApprovalStatsByIds,
  FilingInstance,
  FilingInstanceCreate,
  TableFilter,
} from 'types/shared-types';

import api from './api';

const createFilingApi = (orgId: string, payload: FilingInstanceCreate) => {
  return api.post(`/v1/filings/`, payload, {
    headers: {
      'x-organization-id': orgId,
    },
  });
};

const updateFilingStatus = (orgId: string, filingId: string, payload: any) => {
  return FilingsService.updateFilingV1FilingsFilingIdPut({
    filingId,
    xOrganizationId: orgId,
    requestBody: payload,
  });
};

const getFilings = ({ orgId, params }: { orgId: string; params?: TableFilter }) => {
  return api.get<ApiResponse<FilingInstance>>(`/v1/filings`, {
    headers: {
      'x-organization-id': orgId,
    },
    params,
  });
};

const getFilingForId = (orgId: string, filingId: string) => {
  return api.get(`/v1/filings/${filingId}`, {
    headers: {
      'x-organization-id': orgId,
    },
  });
};

const exportFilingsSummary = (orgId: string, countryCode?: string) => {
  return api.get(`/v1/filings/reports/summary`, {
    headers: {
      'x-organization-id': orgId,
    },
    params: {
      country_code: countryCode,
    },
    responseType: 'arraybuffer',
  });
};

const exportReportForFiling = (orgId: string, filingId: string) => {
  return api.get(`/v1/filings/${filingId}/reports/export`, {
    headers: {
      'x-organization-id': orgId,
    },
    responseType: 'arraybuffer',
  });
};

const getFilingApprovalStatuses = (orgId: string, filing_Ids: string[]) => {
  return api.post<FilingApprovalStatsByIds>('/v1/filings/approval_stats', filing_Ids, {
    headers: {
      'x-organization-id': orgId,
    },
  });
};

const bulkApproveFilings = (orgId: string, filing_ids: string[]) => {
  return api.put<FilingInstance[]>(
    '/v1/filings/bulk/approve',
    { filing_ids },
    {
      headers: {
        'x-organization-id': orgId,
      },
    }
  );
};

const FILINGS_METRICS_KEY = 'filings_metrics';

const FILINGS_STATE_KEY = 'filings';
const FILINGS_BY_ID_STATE_KEY = 'filings_by_id';
const FILINGS_HISTORY_STATE_KEY = 'filings_history';

export {
  bulkApproveFilings,
  createFilingApi,
  exportFilingsSummary,
  exportReportForFiling,
  FILINGS_BY_ID_STATE_KEY,
  FILINGS_HISTORY_STATE_KEY,
  FILINGS_METRICS_KEY,
  FILINGS_STATE_KEY,
  getFilingApprovalStatuses,
  getFilingForId,
  getFilings,
  updateFilingStatus,
};
